@import './variables.scss';
@import '@fortawesome/fontawesome-svg-core/styles.css';

@tailwind base; // https://unpkg.com/tailwindcss@3.3.3/src/css/preflight.css
@tailwind components;
@tailwind utilities;

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  background: #ffffff;
  width: 100%;
  height: 100%;
  @media print {
    overflow-x: unset;
  }
}

svg {
  display: inline-block;
  overflow: visible;
}
